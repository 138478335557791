import styles from './AppCard.module.scss';
import { useMemo } from 'react';
const AppCard = ({
  app: {
    accessible,
    longName,
    subname,
    url,
    iconURL,
    grades
  }
}) => {
  const card = useMemo(() => <>
      <div className={styles["card-image"]}>
        <img src={iconURL} width={70} height={70} />
      </div>
      <div className={styles["card-text"]}>
        <div className={styles["label"]}>{longName}</div>
        {subname && <div className={styles["subtitle"]}>{subname}</div>}
        {grades?.length > 0 && <div className={styles["grade-tag"]}>{grades.length == 1 ? grades[0].name : <>{grades[0].name} - {grades[grades.length - 1].name} </>} </div>}
      </div>
    </>, [iconURL, longName, subname, grades]);
  return accessible ? <a href={url} className={styles["appcard-container"]}>{card}</a> : <span className={`${styles["appcard-container"]} ${styles["locked"]}`}>
      <img src="/assets/lock-icon.png" className={styles["lock-icon"]} />
      {card}
    </span>;
};
export default AppCard;