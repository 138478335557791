import { Button, Card, Image, Result } from 'antd';
const NoReportsMessage = ({
  level
}) => {
  const props = level ? {
    title: `Uh, oh! Looks like you do not have an active ${level}.`,
    subTitle: <p>
				Classes are created by your student information system or your administrator. <br />
				Need help? Contact <a>support@footsteps2brilliance.com</a>{' '}
			</p>,
    extra: [<Button type="link" key="help+faq">
				<a href="https://footsteps2brilliance.freshdesk.com/support/home" target="_blank">
					View Help and FAQs
				</a>
			</Button>]
  } : {
    title: 'It looks like you don\'t currently have any reports!'
  };
  return <Card data-sentry-element="Card" data-sentry-component="NoReportsMessage" data-sentry-source-file="NoReportsMessage.jsx">
			<Result status="info" icon={<Image width={200} src="/assets/sherlock_alert.png" preview={false} />} {...props} data-sentry-element="Result" data-sentry-source-file="NoReportsMessage.jsx" />
		</Card>;
};
export default NoReportsMessage;