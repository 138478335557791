import dayjs from 'dayjs';
import lodash from 'lodash';
import { cloneElement, Children } from 'react';
import ErrorMessage from '../ErrorMessage';
import LoadingCards from '../ui/LoadingCards';
import { useQuery } from '../../lib/hooks';
import * as QueryGenerators from '../../helpers/gqlQueries/metric';
import { formatDateSpan } from '../../helpers/datetime';
const defaultLoading = <LoadingCards />;
const defaultError = <ErrorMessage message="Error loading data" />;
const Query = ({
  vector = false,
  query,
  entity,
  subEntity,
  vars,
  children,
  loadingComp = defaultLoading,
  errorComp = defaultError
}) => {
  if (vars.dateSpan?.from instanceof dayjs && vars.dateSpan?.to instanceof dayjs) {
    vars.dateSpan = {
      from: vars.dateSpan.from.format('YYYY-MM-DD'),
      to: vars.dateSpan.to.format('YYYY-MM-DD')
    };
  }
  const {
    name,
    varsFragment,
    bodyFields
  } = query;
  const [gqlQuery, field] = vector ? QueryGenerators.vector(entity, subEntity, name, varsFragment, bodyFields) : QueryGenerators.scalar(entity, name, varsFragment, bodyFields);
  const {
    loading,
    error,
    data
  } = useQuery(gqlQuery, {
    variables: vars
  });
  if (loading) {
    return loadingComp;
  }
  if (error) {
    return errorComp;
  }
  return Children.map(children, child => cloneElement(child, {
    data: lodash.get(data, field),
    formatedDateSpan: vars.dateSpan && formatDateSpan(vars.dateSpan.from, vars.dateSpan.to)
  }));
};
export default Query;