import styles from './ReportSummaries.module.scss';
import { Button, Divider, Pagination, Space } from 'antd';
import { useContext, useState } from 'react';
import NoReportsMessage from './NoReportsMessage';
import AC from '../AccessControl';
import { ClassMgmtIcon, ReportsIcon } from '../images/NavIcons';
import Achievements from '../queries/scalar/Achievements';
import ReportsSearch from '../navigation/ReportsSearch';
import QuickLinks from '../panels/QuickLinks';
import UiContext from '../../lib/UiContext';
import { useReportsAccess } from '../../lib/hooks';
const EntitySummary = ({
  data
}) => {
  const uiContext = useContext(UiContext);
  return <div className={styles["entity-summary-container"]} key={data.id} data-sentry-component="EntitySummary" data-sentry-source-file="ReportSummaries.jsx">
      <div children={data.name} />
      <QuickLinks title="Report Quick Links" type={data.__typename.replace(/Group$/, '').toLowerCase()} id={data.id} dateSpan={uiContext.dateSpan} display="dropdown" data-sentry-element="QuickLinks" data-sentry-source-file="ReportSummaries.jsx" />
      <div className="compact spacer-div">
        <Achievements entity={data.__typename} id={data.id} dateSpan={uiContext.dateSpan} size="small" data-sentry-element="Achievements" data-sentry-source-file="ReportSummaries.jsx" />
      </div>
    </div>;
};
const ReportSummaries = () => {
  const [currentReport, setCurrentReport] = useState(0);
  const {
    reportEntities
  } = useReportsAccess();
  return <>
      <div className={styles["report-summaries-buttons"]}>
        <AC.ButtonLink href={{
        pathname: '/reports'
      }} icon={<ReportsIcon style={{
        verticalAlign: 'middle'
      }} />} label="All Reports" data-sentry-element="unknown" data-sentry-source-file="ReportSummaries.jsx" />
        <Button href={process.env.classManagement} icon={<ClassMgmtIcon style={{
        verticalAlign: 'middle'
      }} />} children="Class Management" data-sentry-element="Button" data-sentry-source-file="ReportSummaries.jsx" />
        <ReportsSearch data-sentry-element="ReportsSearch" data-sentry-source-file="ReportSummaries.jsx" />
      </div>
      <Divider style={{
      margin: '16px 0px'
    }} data-sentry-element="Divider" data-sentry-source-file="ReportSummaries.jsx" />
      <h2 children="My Reports" />
      {!reportEntities ? <NoReportsMessage /> : reportEntities.length === 0 ? <NoReportsMessage level={reportEntities.type} /> : <>
              <EntitySummary data={reportEntities[currentReport]} />
              <div style={{
        textAlign: 'center'
      }}>
                <Pagination size="small" simple pageSize={1} total={reportEntities.length} onChange={p => setCurrentReport(p - 1)} />
              </div>
            </>}
    </>;
};
export default ReportSummaries;