import { Card, Col, Row } from 'antd';
import { CalendarOutlined } from '@ant-design/icons';
import { LoadingAchievementCard } from '../../ui/LoadingCards';
import Query from '../Query';
import SimpleAchievementBadge from '../../reports/SimpleAchievementBadge';
const titles = {
  literacyHoursDetails: 'Hours of Literacy',
  wordsReadDetails: 'Words Read',
  booksReadDetails: 'Books Read',
  gamesPlayedDetails: 'Games Played',
  booksWrittenDetails: 'Books Written'
};
const dataKeys = Object.keys(titles);
const createBadges = (badgeKey, badgeData) => ({
  title: titles[badgeKey],
  classKey: badgeKey,
  data: badgeData,
  image: `/assets/achievements/${badgeKey.replace('Details', '')}_color.png`
});
const Achievements = ({
  data,
  formatedDateSpan
}) => <Card data-sentry-element="Card" data-sentry-component="Achievements" data-sentry-source-file="Achievements.jsx">
    {' '}
    <Row gutter={[16, 16]} data-sentry-element="Row" data-sentry-source-file="Achievements.jsx">
      {dataKeys.map(key => <Col className="achievement-column" flex={1} wrapkey={key}>
          <SimpleAchievementBadge achievementData={createBadges(key, data[key])} />
        </Col>)}
    </Row>
    <div className="widget-date-span subtitle">
      <CalendarOutlined data-sentry-element="CalendarOutlined" data-sentry-source-file="Achievements.jsx" /> {formatedDateSpan}
    </div>
  </Card>;
const Loading = <Row gutter={16}>
	{dataKeys.map(key => <Col className="achievement-column" span={24 / dataKeys.length} key={key}>
				<LoadingAchievementCard />
			</Col>)}
	</Row>;
const entitiesWithoutGradeFilter = ['ProgramGroup.nonDistrictSchools', 'District.nonDistrictSchools', 'Class'];
const query = {
  name: 'ACHIEVEMENTS',
  varsFragment: entity => `($id: ID!, $dateSpan: DateSpan!${entitiesWithoutGradeFilter.includes(entity) ? '' : ', $grades: [GradeLevel]'}${entity === 'User' ? ', $classID: ID' : ''})`,
  bodyFields: entity => ['literacyHours', 'wordsRead', 'booksRead', 'gamesPlayed', 'booksWritten'].map(f => `
		${f}Details(dateSpan: $dateSpan${entitiesWithoutGradeFilter.includes(entity) ? '' : ', gradeLevels: $grades'}${entity === 'User' ? ', classID: $classID' : ''}) {
			value
			breakdown {
				type
				value
			}
		}
	`).join('\n')
};
export default (({
  entity,
  ...vars
}) => <Query query={query} entity={entity} vars={vars} loadingComp={Loading}>
		<Achievements />
	</Query>);