import gql from 'graphql-tag';
const bodyFragments = {
  'ProgramGroup': `programGroupById(id: $id) { id, `,
  'ProgramGroup.community': `programGroupById(id: $id) { id, community { id, `,
  'ProgramGroup.districtSchools': `programGroupById(id: $id) { id, districtSchools { id, `,
  'ProgramGroup.partnerSchools': `programGroupById(id: $id) { id, partnerSchools { id, `,
  'ProgramGroup.nonDistrictSchools': `programGroupById(id: $id) { id, nonDistrictSchools { id, `,
  'District': `districtById(id: $id){ id, `,
  'District.community': `districtById(id: $id){ id, community { id, `,
  'District.districtSchools': `districtById(id: $id){ id, districtSchools { id, `,
  'District.partnerSchools': `districtById(id: $id){ id, partnerSchools { id, `,
  'District.nonDistrictSchools': `districtById(id: $id){ id, nonDistrictSchools { id, `,
  'School': `schoolById(id: $id){ id, `,
  'Class': `classById(id: $id){ id, `,
  'User': `userById(id: $id){ id, `
};
const getterField = {
  'ProgramGroup': 'programGroupById',
  'ProgramGroup.community': 'programGroupById.community',
  'ProgramGroup.districtSchools': 'programGroupById.districtSchools',
  'ProgramGroup.partnerSchools': 'programGroupById.partnerSchools',
  'ProgramGroup.nonDistrictSchools': 'programGroupById.nonDistrictSchools',
  'District': 'districtById',
  'District.community': 'districtById.community',
  'District.districtSchools': 'districtById.districtSchools',
  'District.partnerSchools': 'districtById.partnerSchools',
  'District.nonDistrictSchools': 'districtById.nonDistrictSchools',
  'School': 'schoolById',
  'Class': 'classById',
  'User': 'userById'
};
const subGetterField = {
  'District': 'districts',
  'Student': 'students',
  'Class': 'classes',
  'School': 'schools'
};
const bodyFragment = (entity, subEntity, bodyFields) => `
	${bodyFragments[entity]}
		${subEntity ? `${subGetterField[subEntity]} { id, name, ${subEntity === 'Class' ? 'type, avatarImageUrl, avatarBorderColor, avatarBackgroundColor, semester, grade, gradeLevel, ' : ''}` : ''}
			${bodyFields(entity, subEntity)}
		${subEntity ? '}' : ''}
	${entity.split('.').fill('}').join('')}
`;
export const scalar = (entity, name, varsFragment, bodyFields) => {
  if (!getterField[entity]) {
    throw new Error(`invalid entity type: ${entity}`);
  }
  const header = `query METRICT__${entity.toUpperCase().replace('.', '_')}__${name} ${varsFragment(entity)}`;
  const query = `${header} { ${bodyFragment(entity, null, bodyFields)} }`;
  return [gql(query), getterField[entity]];
};
export const vector = (entity, subEntity, name, varsFragment, bodyFields) => {
  if (!getterField[entity]) {
    throw new Error(`invalid entity type: ${entity}`);
  }
  if (!subGetterField[subEntity]) {
    throw new Error(`invalid subEntity type: ${subEntity}`);
  }
  const header = `query METRICT__${[entity, subEntity].map(e => e.toUpperCase().replace('.', '_')).join('__')}__${name} ${varsFragment(entity)}`;
  const query = `${header} { ${bodyFragment(entity, subEntity, bodyFields)} }`;
  return [gql(query), `${getterField[entity]}.${subGetterField[subEntity]}`];
};