import { Card, Popover, Statistic } from 'antd';
import lodash, { compact } from 'lodash';
import { formatDurationHours } from '../../helpers/datetime';
const SimpleAchievementBadge = ({
  achievementData,
  size
}) => {
  const {
    classKey,
    title,
    image,
    data
  } = achievementData;
  const dataFormatted = data.value ? data.value.toLocaleString(undefined, {
    maximumFractionDigits: 0
  }) : 0;
  const breakdown = {
    student: lodash.find(data.breakdown, {
      type: 'STUDENT'
    })?.value || 0,
    teacher: lodash.find(data.breakdown, {
      type: 'TEACHER'
    })?.value || 0,
    parent: lodash.find(data.breakdown, {
      type: 'PARENT'
    })?.value || 0,
    other: lodash.find(data.breakdown, {
      type: 'OTHER'
    })?.value || 0
  };
  const studentUsage = breakdown.student > 0 ? title.match(/literacy/i) ? formatDurationHours(breakdown.student) : breakdown.student.toLocaleString(undefined, {
    maximumFractionDigits: 0
  }) : null;
  const teacherUsage = breakdown.teacher > 0 ? title.match(/literacy/i) ? formatDurationHours(breakdown.teacher) : breakdown.teacher.toLocaleString(undefined, {
    maximumFractionDigits: 0
  }) : null;
  const parentUsage = breakdown.parent > 0 ? title.match(/literacy/i) ? formatDurationHours(breakdown.parent) : breakdown.parent.toLocaleString(undefined, {
    maximumFractionDigits: 0
  }) : null;
  const otherUsage = breakdown.other > 0 ? title.match(/literacy/i) ? formatDurationHours(breakdown.other) : breakdown.other.toLocaleString(undefined, {
    maximumFractionDigits: 0
  }) : null;
  return <Popover title={`${title} By:`} placement="bottom" content={<>
          <h4>
            <strong>Total: </strong> {dataFormatted}
          </h4>
          {studentUsage && <>Students: {studentUsage}</>}
          <br />
          {teacherUsage && <>
              Teachers: {teacherUsage}
              <br />
            </>}
          {parentUsage && <>
              Parents: {parentUsage}
              <br />
            </>}
          {otherUsage && <>
              Other: {otherUsage}
              <br />
            </>}
        </>} data-sentry-element="Popover" data-sentry-component="SimpleAchievementBadge" data-sentry-source-file="SimpleAchievementBadge.jsx">
        <div className={`achievement-container ${size ? size : `default`}-size`}>
          <div className="achievement-image-container">
            <img src={image} className="achievement-image" />
          </div>

          <div className="achievement-data" id={classKey}>
            <Statistic title={title} value={dataFormatted} data-sentry-element="Statistic" data-sentry-source-file="SimpleAchievementBadge.jsx" />
          </div>
        </div>
    </Popover>;
};
export default SimpleAchievementBadge;